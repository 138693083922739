import React from "react";
import "../config/i18n";

import { useTranslation } from "react-i18next";
import PageWrapper from "../components/PageWrapper";
import { Link } from 'gatsby';

const Contact = () => {
  
const { t, i18n } = useTranslation();
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <><Link to="https://profile.thedigi.app">
            <button className="btn btn btn-dark-digi btn-sm-2 rounded-8 h-50 font-size-5">
            <i className="fa fa-lock mr-9 font-size-5 " />    {t("login")}   
          </button>
          </Link>
            </>
          ),
          footerStyle: "style6",
        }}
      >
        <div className="min-height-100vh d-flex align-items-center pt-23 pt-md-26 pt-lg-30 pb-8 pb-md-12 pb-lg-23">
          <div className="container">
            {/* Section Title */}
            <div className="row justify-content-center">
              <div className="col-xl-12">
                <div
                  className="mb-10 mb-lg-18 text-center"
                  data-aos="fade-up"
                  data-aos-duration={300}
                  data-aos-once="true"
                >
                  <h2 className="font-size-11 mb-7">
                     {t("partnerships_title")}</h2>
                  <p className="font-size-7 mb-0">
                    {t("partnerships_subtitle")}
                  </p>
                </div>
              </div>
            </div>
            {/* End Section Title */}
            {/* Contact Form */}
            <div className="row justify-content-center">
              <div className="col-12">
                {/* contact details */}
                <div
                  className="top-contact-info bg-default-1 max-w-540 mx-auto py-10 px-13 rounded-10"
                  data-aos="fade-up"
                  data-aos-duration={600}
                  data-aos-once="true">
                      <div className="pl-1 pr-3">
                        <h4 className="font-size-5 text-default-color text-center font-weight-normal mb-0">
                        {t("form_paragraph2")}
                        </h4>
                        <center><a className="font-size-7 font-weight-bold heading-default-color"
                          href="mailto:sales@thedigi.app">
                        partners@thedigi.app
                        </a></center>
                      </div>
                </div>
               {/* <div className="pt-12 pb-10 max-w-536 mx-auto"> 
                  <form
                    name="contact"
                    method="POST"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    data-aos="fade-up"
                    data-aos-duration={1100}
                    data-aos-once="true"
                  >
                    <input type="hidden" name="form-name" value="contact" />
                   
                    <div className="form-group mb-7 position-relative">
                      <input
                        type="text"
                        name="name"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder={t("form_name")}
                        id="name"
                        required
                      />
                    </div>
                     <div className="form-group mb-7 position-relative">
                      <input
                        type="text"
                        name="name"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder={t("form_company")}
                        id="company"
                        required
                      />
                    </div>
                    <div className="form-group mb-7 position-relative">
                      <input
                        type="email"
                        name="email"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder={t("form_email")}
                        id="email"
                        required
                      />
                    </div>
                    <div className="form-group mb-7 position-relative">
                      <input
                        type="text"
                        name="subject"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder={t("form_subject")}
                        id="subject"
                        required
                      />
                    </div>
                    <div className="form-group mb-7 position-relative">
                      <textarea
                        name="message"
                        id="message"
                        placeholder={t("form_message")}
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 pt-7 font-size-5 border-default-color"
                        defaultValue={""}
                        required
                      />
                    </div>
                    <div className="button">
                      <button
                        type="submit"
                        href="/#"
                        className="btn btn-golden-yellow h-55 w-100 rounded-4"
                      >{t("form_button")}
                      </button>
                    </div>
                  </form>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Contact;
